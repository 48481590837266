
import Home from "./views/web/Home.vue";
import TenantChoice from "./views/web/TenantChoice.vue";
import UserChoice from "./views/web/UserChoice.vue";
import BookWorkflow from "./views/web/BookWorkflow.vue";
import Municipality from "./views/web/Municipality.vue";
import Frontoffice from "./views/web/Frontoffice.vue";
import BookLocation from "./views/web/BookLocation.vue";
import BookDate from "./views/web/BookDate.vue";
import BookDateHour from "./views/web/BookDateHour.vue";
import BookMonth from "./views/web/BookMonth.vue";
import BookDay from "./views/web/BookDay.vue";
import BookOrari from "./views/web/BookOrari.vue";
import BookFormChoice from "./views/web/BookFormChoice.vue";
import BookUserData from "./views/web/BookUserData.vue";
import RecallUserData from "./views/web/RecallUserData.vue";
import Summary from "./views/web/Summary.vue";
import SummaryRecall from "./views/web/SummaryRecall.vue";
import EditBook from "./views/web/EditBook.vue";
import EditBookResumeData from "./views/web/EditBookResumeData.vue";
import DeletedSuccessful from "./views/web/DeletedSuccessful.vue";
import Checkin from "./views/web/Checkin.vue";
import BookService from "./views/web/BookService.vue";

/*
import ProBookCategories from "./views/web/pro/ProBookCategories.vue";
import ProBookServices from "./views/web/pro/ProBookServices.vue";
import ProBookDate from "./views/web/pro/ProBookDate.vue";
import ProBookOrari from "./views/web/pro/ProBookOrari.vue";
import ProBookUserData from "./views/web/pro/ProBookUserData.vue";
import ProMunicipality from "./views/web/pro/ProMunicipality.vue";
import ProBookFrontoffice from "./views/web/pro/ProBookFrontoffice.vue";
import ProBookMonth from "./views/web/pro/ProBookMonth.vue";
import ProBookDay from "./views/web/pro/ProBookDay.vue";
*/
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'


const routes: RouteRecordRaw[] = [
	//Home
	{
		path: "/",
		name: "home",
		component: Home
	},

	//TENANT
	{
		path: "/web",
		name: "tenant",
		component: TenantChoice
	},

	//rotta creata per retrocompatibilità dal momento che erano già stati comunicati al cliente url con /tenant
	{
		path: "/web/tenant",
		name: "user",
		component: UserChoice
	},

	//USER CHOICE
	{
		path: "/web/userchoice",
		name: "userchoice",
		component: UserChoice
	},


	//BOOK
	{
		path: "/web/bookflow",
		name: "bookflow",
		component: BookWorkflow
	},
	{
		path: "/web/bookflow/:id",
		name: "bookflowchoice",
		props: true,
		component: BookWorkflow
	},

	{
		path: "/web/bookservice",
		name: "bookservice",
		component: BookService
	},

	{
		path: "/web/bookservice/:id",
		name: "bookservicechoice",
		props: true,
		component: BookService
	},

	{
		path: "/web/municipality",
		name: "municipality",
		component: Municipality
	},

	{
		path: "/web/frontoffice",
		name: "frontoffice",
		component: Frontoffice
	},

	{
		path: "/web/booklocation",
		name: "booklocation",
		component: BookLocation
	},

	{
		path: "/web/bookdate",
		name: "bookdate",
		component: BookDate
	},

	{
		path: "/web/bookdatehour",
		name: "bookdatehour",
		component: BookDateHour
	},

	{
		path: "/web/bookmonth",
		name: "bookmonth",
		component: BookMonth
	},

	{
		path: "/web/bookday",
		name: "bookday",
		component: BookDay
	},

	{
		path: "/web/bookorari",
		name: "bookorari",
		component: BookOrari
	},

	{
		path: "/web/bookformchoice",
		name: "bookformchoice",
		component: BookFormChoice
	},

	{
		path: "/web/bookuserdata",
		name: "bookuserdata",
		component: BookUserData
	},

	{
		path: "/web/recalluserdata",
		name: "recalluserdata",
		component: RecallUserData
	},

	//SUMMARY
	{
		path: "/web/summary",
		name: "summary",
		component: Summary
	},

	{
		path: "/web/summaryrecall",
		name: "summaryRecall",
		component: SummaryRecall
	},

	//EDIT
	{
		path: "/web/editbook",
		name: "editbook",
		component: EditBook
	},

	{
		path: "/web/editbookresumedata",
		name: "editbookresumedata",
		component: EditBookResumeData
	},

	//DELETE
	{
		path: "/web/deleted",
		name: "deleted",
		component: DeletedSuccessful
	},

	//CHECK-IN
	{
		path: "/web/checkin/:keycode",
		name: "checkin",
		component: Checkin
	}

	/*
	//PRO
	{
		path: "/web/probook",
		name: "probook",
		component: ProBookCategories
	},
	{
		path: "/web/probookservices",
		name: "probookservices",
		component: ProBookServices
	},
	{
		path: "/web/promunicipality",
		name: "promunicipality",
		component: ProMunicipality
	},
	{
		path: "/web/probookfrontoffice",
		name: "probookfrontoffice",
		component: ProBookFrontoffice
	},
	{
		path: "/web/probookmonth",
		name: "probookmonth",
		component: ProBookMonth
	},
	{
		path: "/web/probookday",
		name: "probookday",
		component: ProBookDay
	},
	{
		path: "/web/probookdate",
		name: "probookdate",
		component: ProBookDate
	},
	{
		path: "/web/probookorari",
		name: "probookorari",
		component: ProBookOrari
	},
	{
		path: "/web/probookuserdata",
		name: "probookuserdata",
		component: ProBookUserData
	},
*/
]


const router = createRouter({
	history: createWebHashHistory(),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		//if (savedPosition) {
		//	return savedPosition;
		//} else {
		return document.getElementById('re-widget-outer').scrollIntoView({ behavior: 'smooth' });
		//}
	}
})

export default router;
